
const root = `http://localhost:5000/api/`

export function get(model){
    return fetch(root + model)
    .then(res => {
        let data = res.json()
        console.log(data)
        return data
    })
    .catch(err => err)
}

export function post(model, body, token){
    console.log(model)
    console.log(body)
    return fetch(root + model, {
        method: 'POST', 
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(res => {
        let data = res.json()
        console.log(data)
        console.log(res.status)
        return data
    })
    .catch(err => err)
}

export function remove(model, id){
    return fetch(root + model + `/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(res => {
        let data = res.json()
        console.log(data)
        console.log(res.status)
        return data
    })
    .catch(err => err)
}