import React from 'react';

import {
    Form, 
    FormGroup,
    Input,
    Button
} from 'reactstrap';

class UntappdMenuEdits extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            menuId: props.menuId,
            menuName: props.menuName,
            menuDescription: props.menuDescription
        }
    }
    editMenu(){
        // UntappdMenu Menu PUT request
    }
    render(){
        
        return (
            <Form className="edit-form">
                <FormGroup>
                    <label><small>Menu Name</small></label>
                    
                    <Input
                        className="form-control-alternative"
                        placeholder="Name"
                        type="text"
                        defaultValue={this.props.menuName}
                        
                    />
                    <label><small>Menu description</small></label>
                    <Input
                        className="form-control-alternative"
                        placeholder="Description"
                        type="textarea"
                        defaultValue={this.props.menuDescription}
                    />
                    <div className="publish">
                        <div className="group">
                        <label className="custom-toggle">
                        <input defaultChecked type="checkbox" />
                        <span
                            className="custom-toggle-slider rounded-circle success"
                            data-label-off="no"
                            data-label-on="yes"
                        />
                        </label>
                        </div>
                    <small>publish menu?</small>
                    
                    </div>
                    
                </FormGroup>
                <Button block color="primary" size="sm" type="button">
                        Save
                    </Button>   
            </Form>
        )
    }

}

export default UntappdMenuEdits