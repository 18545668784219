import React from 'react'
import { 
    Alert, Badge, Button, Card, CardHeader, CardBody, CardFooter,
    CardImg, CardImgOverlay, CardTitle, CardText, Container, 
    FormGroup, Input, Label, ListGroupItem, ListGroup, Modal, Row, Col
} from "reactstrap"
import CreatableSelect from 'react-select/creatable'
import toaster from 'toasted-notes'
import { get, post, remove } from 'tools/Restful'
import 'toasted-notes/src/styles.css'
import styles from 'components/Galleries/CardGalleryStyles.css'

const portalRoot = document.getElementById('portal')

class MenuCard extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            showModal: false,
            alert: false, 
            editMode: false,
            confirmDelete: false, 
            menu_to_delete_id: '',
            menu_to_delete_name: ''
        }
        this.toggleModal = this.toggleModal.bind(this)
        this.setEditMode = this.setEditMode.bind(this)
        this.handleTags = this.handleTags.bind(this)
       // this.deleteIngredient = this.deleteIngredient.bind(this)
    }
    toggleModal = (state, e, card) => {
        let cardId = card.id
        console.log(card)
        this.setState({
            [state]: !this.state[state]
        })
    }
    confirmDeleteCard = (state, e, card) => {
        console.log(card)
        let cardId = card.id
        let cardName = card.label
        
        console.log(card)
        this.setState({
            menu_to_delete_id: cardId,
            menu_to_delete_name: cardName
        })
        this.toggleModal(state, e, card)
    }
    setEditMode = (e) => {
        this.setState({editMode:true})
    }
    resetEditMode = (e) => {
        this.setState({editMode:false})
    }
    
    handleTags = (e) => {
        let selected = e
        this.setState({chosenTags: selected})
    }
    render(){
        console.log(this.props)
        let card = this.props.data
        let items = this.props.items
        console.log('rendered')
        console.log(items)
        console.log(card)
        return(
            <Card className="card card-content">
                { this.state.editMode === false
                    ?   <div>
                            
                            <CardBody key={card.id}>
                                <CardTitle>{card.label}</CardTitle>
                                <CardText>{card.description}</CardText>
                                <Container className="item-card-tag-container">
                                    <strong>tags</strong>
                                    {card.tags.map(tag => (
                                        <span>
                                            <Badge color="success" className="location-badge">
                                                {tag.name && tag.value ? 
                                                    
                                                    tag.name.label
                                                
                                                : <span></span>
                                                }
                                            </Badge>
                                        </span>
                                    ))}
                                </Container>
                                <Container className="item-card-tag-container">
                                    <strong>sections</strong>
                                    {card.sections.map(section => (
                                        <span>
                                            <Badge color="success" className="location-badge">
                                                {section.name.label} | {section.items.length}
                                            </Badge>
                                        </span>
                                    ))}
                                </Container>
                                <Button
                                    size={"sm"}
                                    className="edit-button"
                                    color="primary"
                                    onClick={e => this.setEditMode(e)}
                                >
                                    edit
                                </Button>
                                <Button
                                    key={card.id}
                                    size='sm'
                                    className='delete-button'
                                    color='danger'
                                    onClick={e => this.setEditMode(e)}
                                    onClick={(e) => this.confirmDeleteCard('confirmDelete', e, card)}
                                >
                                    delete
                                </Button>
                            </CardBody>
                        </div>
                    :   <CardBody>
                            <FormGroup>
                                <Label>Menu Name</Label>
                                <Input 
                                    type="text"
                                    defaultValue={card.label}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Sections</Label>
                                <Container>
                                    <div>Add a section</div>
                                    <Button>Add</Button>
                                </Container>
                                {card.sections.map(section => (
                                    <span>section.name</span>
                                ))}
                            </FormGroup>
                        </CardBody>
                }
            </Card>
        )
    }
}
export default MenuCard
