//import React, { useState } from 'react'
import React from "react"
import { 
    Alert, Badge, Button, ButtonGroup, Card, CardHeader, CardBody, 
    CardFooter, CardImg, CardImgOverlay, CardTitle, CardText, Container, 
    Collapse, Form, FormGroup, Input, InputGroupAddon, InputGroupText, 
    InputGroup, ListGroupItem, ListGroup, Modal, Row, Col
} from "reactstrap"
import CreatableSelect from 'react-select/creatable'
import styles from 'components/Galleries/Cards/CardStyles.css'
import { object } from "prop-types"

const portRoot = document.getElementById('portal')

class UntappdMenuCard extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            showModal: false,
            editMode: false,
            alert: false,
            openedCollapses: ["collapseOne"],
            untappd_menu: []
        }
        this.fetchUntappd = this.fetchUntappd.bind(this)
    }
    fetchUntappd(locId, search){
        let url = `https://blooming-lowlands-36532.herokuapp.com/api/location/${locId}/untappd/search/q=${search}`
        return fetch(url)
        .then(res => res.json())
        .then(data => {
            let beers = data.items.map(item => (
                {
                    id: item.untappd_id,
                    name: item.name,
                    brewery: item.brewery,
                    style: item.style,
                    image: item.label_image,
                    location: item.brewery_location,
                    abv: item.abv
                }
            ))
            return beers
        }).then(beers => {
            this.setState({untappd_beer: beers})
        }).catch(err => err)
    }
    getBeerFromUntappd = (e) => {
         console.log(e.target)
         console.log(`location id: ${this.props.locid}`)
         console.log(`event: ${e.type}`)
    }
    editMode = (e) => {
        this.setState({editMode:true})
    }
    resetEditMode = (e) => {
        this.setState({editMode:false})
    }
    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses
        if (openedCollapses.includes(collapse)){
            this.setState({
                openedCollapses: []
            })
        } else {
            this.setState({
                openedCollapses: [collapse]
            })
        }
    }
    removeTapFromSection = (itemId, sectionId) => {
        console.log(`item id: ${itemId}`)
        console.log(`section id: ${sectionId}`)
        let baseUrl = `https://blooming-lowlands-36532.herokuapp.com/api/`
        let url = baseUrl + `location/${this.props.locid}/untappd/${sectionId}/`
        
        return fetch(url)
        .then(res => res.json())
        .catch(err => err.json())
          
        
        /*
            '/:location_id/untappd/:section_id/delete'
            `https://blooming-lowlands-36532.herokuapp.com/api/location/${locId}/untappd/search/q=${search}`
            function to delete untappd menu item (api) and refresh list
            */
    }
    clickHandler = (e, sectionId, action, itemId) => {
        
        
        if (action === `removeTap`){
            console.log(`removing tap ${itemId} from section ${sectionId}`)
        } else if (action ===  `removeSection`){
            console.log(`removing section ${sectionId}`)
        } else if (action === `addTapToSection`) {
            console.log(`adding tap to section ${sectionId}`)
            this.toggleModal('addTapModal')
            //function to add item to untappd section
        } else if (action === `editTap`) {
            console.log('editing tap')
        } else if (action === `deleteTapFromSection`){
            this.removeTapFromSection(itemId, sectionId)
        }
    }
    toggleModal = state => {
        this.setState({
          [state]: !this.state[state]
        })
    }
   
    render(){
        
        const card = this.props.data
        const model = this.props.model
        const title = this.props.title

        return(
            <Card className="card card-content">
                {
                    this.state.editMode === false
                    ?   <div>
                            <CardBody className="content-card" key={card.id}>
                                <CardTitle>{card.name}</CardTitle>
                                <CardText>{card.description}</CardText>
                                <Container>
                                {
                                    card.sections.map(section => (
                                        <div>
                                            <CardHeader
                                                role="tab"
                                                onClick={() => this.collapsesToggle(`collapse_${section.id}`)}
                                            >
                                                <h5 className="mb-0">{section.name}{" "}
                                                <Badge color="primary" pill>
                                                    {section.items.length}
                                                </Badge>
                                               
                                                </h5>
                                                
                                            </CardHeader>
                                            <Collapse
                                                role="thumbnail"
                                                isOpen={this.state.openedCollapses.includes(`collapse_${section.id}`)}
                                            >
                                                <CardBody className="content-card" key={card.id}>
                                                <Container className="section-tools">
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <Badge 
                                                                    className="badge-button"
                                                                    color="info" 
                                                                    onClick={e => this.clickHandler(e, `${section.id}`, `addTapToSection`)}
                                                                >
                                                                    add tap
                                                                </Badge>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <CreatableSelect
                                                            options={this.state.untappd_beer}
                                                            onChange={e => this.getBeerFromUntappd(e)}
                                                        />
                                                    </InputGroup>
                                                </Container>         
                                                <Container>
                                                    {section.items.map(item => (
                                                       
                                                        <div>
                                                            <Badge 
                                                                className="untappd-beer-list"
                                                                color="primary"
                                                            >
                                                                {item.name}
                                                                {" "}
                                                                <i
                                                                    class="untappd-list-badge fas fa-edit"
                                                                    onClick={e => this.clickHandler(e, `${section.id}`, `editTap`)}
                                                                ></i>
                                                                {" "}
                                                                <i 
                                                                    class="untappd-list-badge fa fa-minus-circle"
                                                                    onClick={e => this.clickHandler(e, `${section.id}`, `deleteTapFromSection`, `${item.id}`)}
                                                                ></i>
                                                            </Badge>
                                                            <br></br>
                                                        </div>
                                                    ))}
                                                </Container>
                                                    
                                                </CardBody>
                                                
                                            </Collapse>
                                        </div>
                                    ))
                                }
                                </Container>
                                </CardBody>
                        </div>
                    :
                        <div>
                            
                        </div>

                }
                <Modal
                    className="modal-dialog-centered spacer"
                    isOpen={this.state.addTapModal}
                    toggle={() => this.toggleModal('addTapModal')}    
                >
                    add tap modal
                </Modal>
            </Card>
        )
    }
}
export default UntappdMenuCard
//const UntappdMenuCard = (props) => {
 /*   console.log(props)
    const [state, setState] = useState({editMode: false, alert: false})
    const card = props.data
    const model = props.model
    const title = props.title

    const setEditMode = (e) => {
        setState({editMode:true})
    }
    const resetEditMode = (e) => {
        setState({
            editMode: false
        })
    }
    return (
        <Card className="card card-content">
            {
                state.editMode === false
                ?   <div>
                        <CardBody key={card.id}>
                            <CardTitle>{card.name}</CardTitle>
                            <CardText>{card.description}</CardText>
                            <Container>
                                {
                                    card.sections.map(section => (
                                        <CardHeader
                                            role="tab"
                                            
                                            
                                        >
                                            <h5 className="mb-0">{section.name}</h5>
                                        </CardHeader>
                                    ))
                                }
                            </Container>
                        </CardBody>
                    </div>
                :   <div>
                        Edit Mode
                    </div>
            }
        </Card>
    )
*/
