import React from "react";
import classnames from "classnames";
import {
    NavItem, NavLink, Nav, TabContent, TabPane,
    Container, Row, Col,
} from "reactstrap";
import CardGalleryHorizontal from 'components/Galleries/CardGalleryHorizontal'
import LocationHeader from "components/Headers/LocationHeader";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import CardsHeader from "components/Headers/CardsHeader.jsx";
import UntappdMenuEdit from "views/pages/location/UntappdMenuEdit";
import StatusBar from "views/pages/location/UntappdMenuStatusBar";
import { widgetEvents } from "variables/general.jsx";
import styles from "views/pages/location/styles.css"
import { IoT1ClickProjects } from "aws-sdk";

let calendar;

class Location extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loc: {
                name: props.location.name,
                id: props.location.id
            },
            locations: [],
            location: [],
            hours: [],
            promos: [],
            slices: [],
            untappd_menus: [],
            positions: [],
            tags: []
        }
        this.fetchData = this.fetchData.bind(this)
        this.toggleGallery = this.toggleGallery.bind(this)
    }
    fetchData(model, id){

        let url = `http://localhost:5000/api/`
        if (model === 'location'){
           url = `http://localhost:5000/api/${model}/${id}/full`
           console.log('hit location')
        
        } else if (model === 'hours' || model === 'promo'){
            url = `${url}${model}/`
        } else if (model === 'locations'){
            url = `${url}location`
        }
        return fetch(url)
        .then(res => res.json())
        .then(data => {
           return data
        }).catch(err => err)
        
    }
    componentDidMount(){
        const fD = this.fetchData
        let currentLocation = this.props.location.name
        let currentLocationId = this.props.location.id
        console.log(currentLocation)
        console.log(currentLocationId)
       
        return Promise.all([
            fD('location', currentLocationId), fD('locations'), fD('promo')
        ])
        .then(([loc, locs, promo]) => {
            console.log(locs)
            this.setState({
                locations: locs,
                location: loc, 
                promos: promo,
                untappd_menus: loc.untappd_menus
            })
        }).catch(err => err)
    }
    toggleGallery = (event, state, index) => {
        event.preventDefault()
        this.setState({
            [state]: index
        })
    }
    render(){
        console.log(this.state)
        return (
            <div style={styles.wrapper}>
                <LocationHeader 
                    location={this.state.location}
                    locationName={this.props.location.name}
                    locationId={this.props.location.id}
                />
                <Container>
                    <Row>
                        <Col sm="4">{this.state.tabs}</Col>
                        <Col sm="8">
                            <Nav
                                className="nav-fill flex-column flex-md-row"
                                id="tabs-icons-text"
                                pills
                                role="tablist"
                            >
                                <NavLink
                                    aria-selected={this.state.tabs === 'promo'}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: this.state.tabs === 'promo'
                                    })}
                                    onClick={e => this.toggleGallery(e, "tabs", 'promo')}
                                    href="#pablo"
                                    role="tab"
                                >
                                    Promos
                                </NavLink>
                                <NavLink
                                     aria-selected={this.state.tabs === 'untappd'}
                                     className={classnames("mb-sm-3 mb-md-0", {
                                         active: this.state.tabs === 'untappd'
                                     })}
                                     onClick={e => this.toggleGallery(e, "tabs", 'untappd')}
                                     href="#pablo"
                                     role="tab"
                                >
                                    Untappd Menus
                                </NavLink>
                            </Nav>
                        </Col>
                    </Row>  
                </Container>
                <TabContent activeTab={`tabs${this.state.tabs}`}>
                    <TabPane tabId="tabspromo">
                        <CardGalleryHorizontal 
                            title="promos"
                            model={`promo`}
                            data={this.state.promos}
                        />
                    </TabPane>
                    <TabPane tabId="tabsuntappd">
                        <CardGalleryHorizontal 
                            title="untappd"
                            model={`untappd`}
                            data={this.state.untappd_menus}
                            locid={this.props.location.id}
                        />
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}
export default Location