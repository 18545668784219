import React from "react"
import { get, post } from 'tools/Restful'
import {
    FormGroup,
    Form, Input,
    Button
} from "reactstrap"
import CreatableSelect from 'react-select/creatable'


import styles from 'components/Forms/FormStyles.css'

class NewIngredient extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            name: '',
            label: '',
            description: '',
            type: '',
            tags: [],
            cost: '',
            notes: '',
            newTags: [],
            chosenTags: [],
        }
        this.submitIngredient = this.submitIngredient.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleIngredientTypes = this.handleIngredientTypes.bind(this)
        this.callbackFunction = this.callbackFunction.bind(this)
        this.prepareIngredient = this.prepareIngredient.bind(this)
        this.handleTags = this.handleTags.bind(this)
        //this.getIngredients = this.getIngredients.bind(this)

        console.log(this.props.tags)
    }

    callbackFunction = (childData) => {
        this.setState({
            tags: [...childData]
        })
    }
    handleTags(event){
        let newTags
        this.setState({chosenTags: event})
        if (event){
            newTags = event.filter(tag => tag.__isNew__ === true)
            if (newTags.length > 0){
                this.setState({newTags: newTags})
            }
        }
    }
    handleInputChange(event){
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        const internal_name = value.replace(/ /g, '_').toLowerCase()
        console.log(value)
        this.setState({
            [name]: value
        })

        if (name === 'name'){
            this.setState({
                label: value,
                internal: internal_name
            })
        } 
    }
    handleIngredientTypes(event){
        console.log(event)
        this.setState({type: event})
    }
    
    submitIngredient(event, props) {
        let addTagsToDB = this.state.newTags.map(tag => ({label: tag.label, value: (tag.value).toLowerCase()}))
        addTagsToDB.map(tag => post('tag', tag))

        let newIngredient = {
            name: this.state.name,
            label: this.state.internal,
            description: this.state.description,
            type: this.state.type,
            cost: this.state.cost,
            notes: this.state.notes,
            tags: this.state.chosenTags,
            ingredientOrderType: this.state.ingredientOrderType.id
        }
        
        console.log(newIngredient)
        
        
        fetch('http://localhost:3000/api/ingredient', {
            method: 'POST',
            body: JSON.stringify(newIngredient),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res, props) => {
            console.log(res.status)
            console.log(props)
        }).catch((err) => err)
        event.preventDefault() 
        this.getIngredients()
    }
    
    submitData = (e) => {
        e.preventDefault()
        this.prepareIngredient()

    }
    prepareIngredient(){

        let addTagsToDB = this.state.newTags.map(tag => ({label: tag.label, value: (tag.value).toLowerCase()}))
        
        addTagsToDB.map(tag => {
            post('tag', tag)
        })

        let newIngredient = {
            name: this.state.name,
            label: this.state.internal,
            description: this.state.description,
            type: this.state.type.id,
            cost: this.state.cost,
            notes: this.state.notes,
            tags: this.state.chosenTags,
        }
        console.log(newIngredient)
        let submitIngredient = () => new Promise((r) => r(post('ingredient', newIngredient)))
        submitIngredient().then(data => console.log(data)).catch(err => console.log(err))
    }
    render(){
        return (
            <Form className="package">
                <FormGroup>

                </FormGroup>
                <FormGroup>
                    <label htmlFor="name">Name</label>
                    <Input
                        className="stuff"
                        name="name"
                        id="name"
                        type="text"
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="name">Internal Name</label>
                    <Input
                        className="internal"
                        name="internal"
                        id="internal"
                        type="text"
                        onChange={this.handleInputChange}
                        value={this.state.internal}
                        readOnly
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="description">Description</label>
                    <Input
                        id="description"
                        name="description"
                        type="textarea"
                        rows="3"
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="type">Type</label>
                    <CreatableSelect
                        id="type"
                        name="type"
                        type="select"
                        onChange={this.handleIngredientTypes}
                        options={this.props.ingredientTypes}
                        value={this.state.chosenIngredientType}
                    >   
                    
                    </CreatableSelect>
                </FormGroup>
                <FormGroup>
                    <label>Tags</label>
                    <CreatableSelect 
                        isMulti
                        options={this.props.tags}
                        onChange={this.handleTags}
                        value={this.state.chosenTags}
                       
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="cost">Cost</label>
                    <Input
                        id="cost"
                        name="cost"
                        type="text"
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="description">Notes</label>
                    <Input
                        id="notes"
                        name="notes"
                        type="textarea"
                        rows="3"
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <Button
                    onClick={event => this.prepareIngredient(event)}
                >
                    Submit
                </Button>
                
            </Form>
        )
    }
}
export default NewIngredient