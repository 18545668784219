//REFACTOR THIS COMPONENT

import React from 'react'
import {
    Container, FormGroup,
    Input, InputGroupAddon,
    InputGroupText, InputGroup
} from 'reactstrap'

import InputByTag from 'components/Forms/inputs/InputByTag'

class PriceInput extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            prices: []
        }
        this.handleChange = this.handleChange.bind(this)
    }
    pricesToParent = () => {
        this.props.parentCallback(this.state.prices)
    }
    handleChange(event){
        let priceArray = document.getElementsByName('item-prices')
        let selected = Array.from(priceArray)
        let selectedArray = []
        console.log(selected)
         for (var i = 0; i < selected.length; i++){
            selectedArray.push({
                size: selected[i].attributes.basize.value,
                amount: selected[i].value,
                diet: selected[i].attributes.diet.value
            })
        }
        console.log(selectedArray)

        this.setState({
            prices: selectedArray
        })
        this.pricesToParent()
    }
    render(){
        console.log(this.state)
        const {prices, value} = this.state
        
        let category, tags, simpleTags

        if (this.props.category){
            category = this.props.category.value
        } else {
            category = ''
        }

        if (this.props.tags){
            tags = this.props.tags
            simpleTags = tags.map(tag => tag.value)
            simpleTags = simpleTags.filter(tag => tag == "gfr" || tag == 'vr')

        } else {
            tags = []
            simpleTags = []
        }
        /*  let category = this.props.category.value
        let tags = this.props.tags
        let simpleTags = tags.map(tag => tag.value)
        simpleTags = simpleTags.filter(tag => tag == "gfr" || tag == 'vr')
        */
    
        if (category == 'appetizers' || category == 'pasta' || category == "deli-style_hoagies"){
            if (category == 'deli-style_hoagies' || category == 'parmigiana_hoagies' && this.props.tags){
                simpleTags = tags.map(tag => tag.value)
                simpleTags = simpleTags.filter(tag => tag == "vr")
            }
            return (
                <Container>
                    {
                        <div>
                        <FormGroup>
                            <InputGroup className="dynamicInput">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>reg</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    name="item-prices"
                                    baSize="reg"
                                    diet="reg"
                                    type="text"
                                    field={`price`}
                                    placeholder={`enter reg price`}
                                    ref="prices"
                                    onChange={this.handleChange}
                                />
                                {
                                    simpleTags.map(tag => (
                                        <Input
                                            name="item-prices"
                                            baSize="reg"
                                            diet={`${tag}`}
                                            type="text"
                                            field={`price ${tag}`}
                                            placeholder={`enter ${tag} price`}
                                            ref="prices"
                                            onChange={this.handleChange}
                                        />
                                    ))
                                }
                                
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>party</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    name="item-prices"
                                    baSize="party"
                                    diet="reg"
                                    type="text"
                                    field={`price`}
                                    placeholder={`enter party price`}
                                    ref="prices"
                                    onChange={this.handleChange}
                                />
                                {
                                    simpleTags.map(tag => (
                                        <Input
                                            name="item-prices"
                                            baSize="party"
                                            diet={`${tag}`}
                                            type="text"
                                            field={`price ${tag}`}
                                            placeholder={`enter ${tag} price`}
                                            ref="prices"
                                            onChange={this.handleChange}
                                        />
                                    ))
                                }
                            </InputGroup>
                        </FormGroup>
                            
                        </div>
                    }
                </Container>
            )
        } else if (category == 'salads'){
            return (
                <Container>
                {
                    <div>
                    <FormGroup>
                        <InputGroup className="dynamicInput">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>Sm</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                name="item-prices"
                                baSize="sm"
                                diet="reg"
                                type="text"
                                field={`price`}
                                placeholder={`enter reg price`}
                                ref="prices"
                                onChange={this.handleChange}
                            />
                            {
                                simpleTags.map(tag => (
                                    <Input
                                        name="item-prices"
                                        baSize="sm"
                                        diet={tag}
                                        type="text"
                                        field={`price ${tag}`}
                                        placeholder={`enter ${tag} price`}
                                        ref="prices"
                                        onChange={this.handleChange}
                                    />
                                ))
                            }
                            
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>Lg</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                name="item-prices"
                                baSize="lg"
                                diet="reg"
                                type="text"
                                field={`price`}
                                placeholder={`enter reg price`}
                                ref="prices"
                                onChange={this.handleChange}
                            />
                            {
                                simpleTags.map(tag => (
                                    <Input
                                        name="item-prices"
                                        baSize="lg"
                                        diet={tag}
                                        type="text"
                                        field={`price ${tag}`}
                                        placeholder={`enter ${tag} price`}
                                        ref="prices"
                                        onChange={this.handleChange}
                                    />
                                ))
                            }
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>Party</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                name="item-prices"
                                baSize="party"
                                diet="reg"
                                type="text"
                                field={`price`}
                                placeholder={`enter reg price`}
                                ref="prices"
                                onChange={this.handleChange}
                            />
                            {
                                simpleTags.map(tag => (
                                    <Input
                                        name="item-prices"
                                        baSize="party"
                                        diet={tag}
                                        type="text"
                                        field={`price ${tag}`}
                                        placeholder={`enter ${tag} price`}
                                        ref="prices"
                                        onChange={this.handleChange}
                                    />
                                ))
                            }
                        </InputGroup>
                    </FormGroup>
                           
                    </div>
                }
            </Container>
            )
        } else if (category == "specialty_pizza"){
            let modTags = simpleTags.filter(tag => tag == "vr")

            return(
                <Container>
                {
                    <div>
                    <FormGroup>
                        <InputGroup className="dynamicInput">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>Sm</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                name="item-prices"
                                baSize="sm"
                                diet="reg"
                                type="text"
                                field={`price`}
                                placeholder={`enter reg price`}
                                ref="prices"
                                onChange={this.handleChange}
                            />
                            {
                                modTags.map(tag => (
                                    <Input
                                        name="item-prices"
                                        baSize="sm"
                                        diet={tag}
                                        type="text"
                                        field={`price ${tag}`}
                                        placeholder={`enter ${tag} price`}
                                        ref="prices"
                                        onChange={this.handleChange}
                                    />
                                ))
                            }
                            
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>Med</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                name="item-prices"
                                baSize="med"
                                diet="reg"
                                type="text"
                                field={`price`}
                                placeholder={`enter med price`}
                                ref="prices"
                                onChange={this.handleChange}
                            />
                            {
                                modTags.map(tag => (
                                    <Input
                                        name="item-prices"
                                        baSize="med"
                                        diet={tag}
                                        type="text"
                                        field={`price ${tag}`}
                                        placeholder={`enter ${tag} price`}
                                        ref="prices"
                                        onChange={this.handleChange}
                                    />
                                ))
                            }
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>Lg</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                name="item-prices"
                                baSize="large"
                                diet="reg"
                                type="text"
                                field={`price`}
                                placeholder={`enter reg price`}
                                ref="prices"
                                onChange={this.handleChange}
                            />
                            {
                                modTags.map(tag => (
                                    <Input
                                        name="item-prices"
                                        baSize="party"
                                        diet={tag}
                                        type="text"
                                        field={`price ${tag}`}
                                        placeholder={`enter ${tag} price`}
                                        ref="prices"
                                        onChange={this.handleChange}
                                    />
                                ))
                            }
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className="dynamicInput">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>Sm</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                name="item-prices"
                                baSize="sm"
                                diet="reg"
                                type="text"
                                field={`price`}
                                placeholder={`enter reg price`}
                                ref="prices"
                                onChange={this.handleChange}
                            />
                            {
                                modTags.map(tag => (
                                    <Input
                                        name="item-prices"
                                        baSize="sm"
                                        diet={tag}
                                        type="text"
                                        field={`price ${tag}`}
                                        placeholder={`enter ${tag} price`}
                                        ref="prices"
                                        onChange={this.handleChange}
                                    />
                                ))
                            }
                            
                        </InputGroup>
                    </FormGroup>
                    {
                        tags.includes('gf') ?
                        <div>GF Stuff</div>
                        :<span></span>
                    }
                    </div>
                }
            </Container>
            )
        } else if (category == "omelets") {
            return (
                <FormGroup>
                    <InputGroup className="dynamicInput">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>reg</InputGroupText>
                        </InputGroupAddon>
                            <Input
                                name="item-prices"
                                baSize="reg"
                                diet="reg"
                                type="text"
                                field={`price`}
                                placeholder={`enter reg price`}
                                ref="prices"
                                onChange={this.handleChange}
                            />
                    </InputGroup>
                </FormGroup>
            )
        } else if (category == "benedicts") {
            return (
                <FormGroup>
                <InputGroup className="dynamicInput">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>reg</InputGroupText>
                    </InputGroupAddon>
                        <Input
                            name="item-prices"
                            baSize="reg"
                            diet="reg"
                            type="text"
                            field={`price`}
                            placeholder={`enter reg price`}
                            ref="prices"
                            onChange={this.handleChange}
                        />
                </InputGroup>
            </FormGroup>
            )
        } else if (category == "favorites" || category == "something_sweet" || category == "desserts") {
            return (
                <FormGroup>
                    <InputGroup className="dynamicInput">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>reg</InputGroupText>
                        </InputGroupAddon>
                            <Input
                                name="item-prices"
                                baSize="reg"
                                diet="reg"
                                type="text"
                                field={`price`}
                                placeholder={`enter reg price`}
                                ref="prices"
                                onChange={this.handleChange}
                            />
                            {
                                simpleTags.map(tag => (
                                    <Input
                                        name="item-prices"
                                        baSize="reg"
                                        diet={tag}
                                        type="text"
                                        field={`price ${tag}`}
                                        placeholder={`enter ${tag} price`}
                                        ref="prices"
                                        onChange={this.handleChange}
                                    />
                                ))
                            }
                    </InputGroup>
                </FormGroup>
                
            )
        } else {
            
               return <span> Please select a category </span>
            
        }
    }
}
export default PriceInput