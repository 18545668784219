import React from 'react'
import { FormGroup, Input, Container, Button } from "reactstrap"
import { useInput } from '../../hooks/useInput';
import { post } from 'tools/Restful'

const NewTag = () => {
    
    const { value:label, bind:bindlabel, reset:resetlabel } = useInput('')
    const { value:value, bind:bindvalue, reset:resetvalue } = useInput('')
    const { value:notes, bind:bindnotes, reset:resetnotes } = useInput('')
    const { value:description, bind:binddescription, reset: resetdescription } = useInput('')

    let tagValue = value.toLowerCase()

    const submitData = (e) => {
        e.preventDefault()
        prepareTag(e)
        resetlabel()
        resetvalue()
        resetnotes()
        resetdescription()
    }
    const prepareTag = () => {
    
        let newTag = {
            name: {
                label: label,
                value: label.replace(/ /g, '_').toLowerCase()
            },
            description: description,
            notes: notes, 
        }
        
        let submitTag = () => new Promise((r) => r(post(`tag`, newTag)))
        submitTag().then(data => console.log(data)).catch(err => console.log(err))
    }
   
    console.log(label)
    console.log(value)
    const internal_name = value.replace(/ /g, '_').toLowerCase()
    return(
        
        <Container>
            <h4>create new tag</h4>
            <FormGroup>
                <label>Label</label>
                <Input
                    type="text"
                    {...bindlabel}
                />
            </FormGroup>
            <FormGroup>
                <label>Internal Name</label>
                <Input
                    className="internal"
                    name="internal"
                    type="text"
                    value={label.replace(/ /g, '_').toLowerCase()}
                    readOnly
                />
            </FormGroup>
            <FormGroup>
                <label>Description</label>
                <Input
                    type="textarea"
                    {...binddescription}
                />
            </FormGroup>
            <FormGroup>
                <label>Notes</label>
                <Input
                    type="textarea"
                    {...bindnotes}
                />
            </FormGroup>
            <Button
                onClick={event => submitData(event)}
            >
                submit
            </Button>
        </Container>
    )
}
export default NewTag