import React from 'react'
import {
    Input, InputGroupAddon,
    InputGroupText, InputGroup
} from 'reactstrap'


const ENTER_KEY = 13
const COMMA_KEY = 188
const BACKSPACE_KEY = 8

class ItemDescriptionInput extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            descriptions: []
        }
        this.handleChange = this.handleChange.bind(this)
    }
    descriptionsToParent = () => {
        this.props.parentCallback(this.state.descriptions)
    }
    handleChange(event){
        console.log(event.target.value)
        console.log(event)
        let descriptionArray = document.getElementsByName('item-descriptions')
        console.log(descriptionArray)
        let selected = Array.from(descriptionArray)
        let selectedArray = []
        for(var i = 0; i < selected.length; i++){
            selectedArray.push({
                ordertype: selected[i].attributes.ordertype.value,
                description_text: selected[i].value
            })
        }
        console.log(selectedArray)
        
        this.setState({
            descriptions: selectedArray
        })
        this.descriptionsToParent()
    }
    
    render(){
        console.log(this.state)
        console.log(this.props)
        const {descriptions, value} = this.state
        if (this.props.orderTypes){
            if(this.props.orderTypes.length > 0){
                return(
                        <div>
                            { 
                            this.props.orderTypes.length > 0 ?
                                    this.props.orderTypes.map(orderType => (
                                        
                                        <InputGroup className="dynamicInput">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>{orderType.label}</InputGroupText>
                                            </InputGroupAddon>
                                                <Input
                                                    type="textarea"
                                                    name="item-descriptions"
                                                    field={`description ${orderType.label}`}
                                                    ordertype={orderType.label}
                                                    placeholder={`enter ${orderType.label} description`}
                                                    ref="descriptions"
                                                    onChange={this.handleChange}
                                                />
                                        </InputGroup>                                    
                                    ))
                                :
                                <span></span>
                            }
                        
                        </div>
                
                )
            } else {
                return(<span> Please select an Order Type </span>)
            }
            
        } else {
            return (<span> Please select an Order Type </span>)
        }
        }
            
}
export default ItemDescriptionInput