import React, { useState } from 'react'
import { 
    Alert, Badge, Button, Card, CardHeader, CardBody, CardFooter,
    CardImg, CardImgOverlay, CardTitle, CardText, Container, 
    Form, FormGroup, Input, ListGroupItem, ListGroup, Row, Col
} from "reactstrap"
import PriceInput from 'components/Forms/PriceInput'
import ItemDescriptionInput from 'components/Forms/ItemDescriptionInput'
import toaster from 'toasted-notes'
import CreatableSelect from 'react-select/creatable'
import { get, post, remove } from 'tools/Restful'
import 'toasted-notes/src/styles.css'

import styles from 'components/Galleries/Cards/CardStyles.css'

const portalRoot = document.getElementById('portal')

const ItemCard = (props) => {
    const [state, setState] = useState({editMode: false, alert: false})
    const card = props.data
    const model = props.model
    const title = props.title
    

    const setEditMode = (e) => {
        setState({editMode:true})
    }
    const resetEditMode = (e) => {
        setState({
            editMode: false
        })
    }
    const deleteCard = (e, id, model) => {
        const removeItem = () => remove(model, id).then((data) => data)
        e.preventDefault()
        removeItem()
        toaster.notify(({ onClose }) => (
            <Alert
                color="default"
                className="alert"
            >
                <div className="alert-text">
                    <Row>
                        <div onClick={onClose}>
                            <i class="remove fas fa-times"></i>
                        </div>
                    </Row>
                    <Row>
                        <span className="alert-title" data-notify="title">
                            <strong> {card.title} Deleted! {model} </strong>
                        </span>
                    </Row>
                </div>
            </Alert>
        ), {duration: 2000});
    }

    return (
        <Card className="card card-content">
            {   state.editMode === false
                ?   <div>
                    <CardImg
                        alt="..."
                        src={card.image}
                        top
                    />
                    <CardBody
                        key={card.id}
                    >
                        
                        <CardTitle>{card.label}</CardTitle>
                            <Container className='item-card-location-container'>
                                <strong>locations</strong>
                                    {card.locations.map(location => (
                                        <span>
                                            <Badge color="info" className="location-badge">
                                                {location.name.value}
                                            </Badge>
                                        </span>
                                    ))}
                            </Container>
                            <Container className='item-card-tag-container'>
                                <strong>tags</strong>
                                    {card.tags.map(tag => (
                                        <span>
                                            <Badge color="success" className="location-badge">
                                                {tag.name.label}
                                            </Badge>
                                        </span>
                                    ))}            
                            </Container>   
                           
                        <Button
                            size="sm"
                            className="edit-button"
                            color="primary"
                            onClick={e => setEditMode(e)}
                        >
                            edit
                        </Button>
                        <Button
                            key={card.id}
                            size='sm'
                            className='delete-button'
                            color='danger'
                            onClick={e => deleteCard(e, card.id, model)}
                        >
                            delete
                        </Button>
                    </CardBody>
                    </div>
                :   <CardBody>
                        <Form className="package">
                            <FormGroup>
                                <label>Name</label>
                                <Input 
                                    className="stuff"
                                    type="text"
                                    value={card.title}
                                />
                            </FormGroup>
                            <Input 
                                type="text"
                                value={card.title}
                            />
                            <Input 
                                type="textarea"
                                value={card.description}
                            />
                            <Button
                                size="sm"
                                color="default"
                                onClick={e => resetEditMode(e)}
                            >
                                Back
                            </Button>
                            <Button
                                size="sm"
                                color="primary"
                                onClick={e => resetEditMode(e)}
                            >
                                Submit
                            </Button>
                        </Form>
                    </CardBody>
            }
        </Card>
    )
}
export default ItemCard