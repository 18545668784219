/*eslint-disable*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
import Callback from '../callback/Callback'
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import IndexHeader from "components/Headers/IndexHeader";
import AuthFooter from "components/Footers/AuthFooter.jsx";



class Index extends React.Component {
  
  render() {
    return (
      <>
     
        <div>
          
          Holding page for web admin tools
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Index;
