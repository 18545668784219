import React, { useState } from 'react'
import { 
    Button, Card, CardHeader, CardBody, CardFooter,
    CardImg, CardImgOverlay, CardTitle, CardText, Container, 
    ListGroupItem, ListGroup, Row, Col
} from "reactstrap";

import styles from 'components/Galleries/CardGalleryStyles.css'
import DynamicCard from './Cards/DynamicCard';

class CardGalleryHorizontal extends React.Component {
    constructor(props){
        super(props)
        this.state = {

        }
       
    }
    
    render(){
        const cards = this.props.data
        const title = this.props.title
        const galleryTitle = this.props.title
        const model = this.props.model
        const tags = this.props.tags
         const items = this.props.items
        console.log(this.props)
        
        return (
            <div>                                                                                                                                                                                                                                                                                                       
                <Container
                    className='wrapper-horizontal'
                >
                    {this.props.data.length >= 1 ? cards.map(card =>
                        <DynamicCard 
                            title={title}
                            data={card}
                            model={model}
                            tags={tags}
                            items={items}
                        />
                    )
                    : <span></span>
                    }
                </Container>
            </div>
            
        )
    }
}

/*
const CardGalleryHorizontal = (props) => {
    console.log(props)
    const cards = props.data
    const title = props.title
    const galleryTitle = props.title
    const model = props.model
    const tags = props.tags
    
    return (
        <div>                                                                                                                                                                                                                                                                                                       
            <Container
                className='wrapper-horizontal'
            >
                {props.data.length >= 1 ? cards.map(card =>
                    <DynamicCard 
                        title={title}
                        data={card}
                        model={model}
                        tags={tags}
                    />
                )
                : <span></span>
                }
            </Container>
        </div>
        
    )
}
*/

export default CardGalleryHorizontal