import React from 'react'
import {
    FormGroup, Form, Input,
    Container, Button
} from "reactstrap"
import Select, {components} from 'react-select'

//tools

import {get, post} from 'tools/Restful'

class NewOrderType extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            label: '',
            value: '',
            internal: '',
            description: '',
            notes: '',
            chosenLocations: []
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.resetState = this.resetState.bind(this)
    }
    handleInputChange(event){
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        const internal_name = value.replace(/ /g, '_').toLowerCase()
        
        this.setState({
            [name]: value
        })
        if(name === 'name'){
            
            this.setState({
                label: value,
                internal: internal_name
            })
        }
    }
    handleSelect(event){
        let selectedLocationIds = event.map(location => location.id)
        this.setState({
            chosenLocations: selectedLocationIds
        })
    }
    resetState(){
        console.log('reset')
        const initialState = {
            name: '',
            label: '',
            value: '',
            internal: '',
            description: '',
            notes: '',
            chosenLocations: []
        }
        this.setState(initialState)
        console.log(this.state)
    }
    handleSubmit(event){
        console.log(this.state)
        
        const newOrderType = {
            label: this.state.name,
            value: this.state.internal,
            locations: this.state.chosenLocations,
            description: this.state.description,
            notes: this.state.notes,
            chosenLocations: this.state.chosenLocations
        }
        console.log(newOrderType)
        

        function submitOrderType(){
            return new Promise((resolve, reject) => {
                resolve(post('ordertype', newOrderType))
            })
        }
        submitOrderType()
            .then(data => console.log(data))
            .catch(error => console.log(error))
        
    }
    render(){
        return(
            <Container>
                <h4>create order-type</h4>
                <Form className="package">
                    <FormGroup>
                        <label>Name</label>
                        <Input
                            name="name"
                            type="text"
                            onChange={this.handleInputChange}
                            
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Internal Name</label>
                        <Input
                            name="internal"
                            type="text"
                            onChange={this.handleInputChange}
                            value={this.state.internal}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Description</label>
                        <Input
                            name="description"
                            type="textarea"
                            onChange={this.handleInputChange}
                            
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Notes</label>
                        <Input
                            name="notes"
                            type="textarea"
                            onChange={this.handleInputChange}
                            
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Locations</label>
                        <Select
                            name='locations'
                            closeMenuOnSelect={false}
                            isMulti
                            options={this.props.locations}
                            onChange={this.handleSelect}
                            
                        />
                    </FormGroup>
                    <Button
                        color="primary"
                        onClick={this.handleSubmit}
                    >
                        submit
                    </Button>
                </Form>
            </Container>
        )
    }
}
export default NewOrderType