import React from "react";
import classnames from "classnames";

import {
		  NavItem, NavLink, Nav, TabContent, TabPane,
		  Container, Row, Col,
	 } from "reactstrap";

import MenuHeader from "components/Headers/MenuHeader";
import CardGalleryHorizontal from 'components/Galleries/CardGalleryHorizontal'
import styles from "views/pages/location/styles.css"


class Menu extends React.Component {
	 constructor(props){
		super(props)
		this.state = {
				items: [],
				ingredients: [],
				orderTypes: [],
				locations: [],
				menus: [],
				categories: [],
				tags: [],
				visibleGallery: '',
				visibleGalleryData: ''
		}
		this.toggleGallery = this.toggleGallery.bind(this)
		this.fetchData = this.fetchData.bind(this)
	}

		  fetchData(model){
				return fetch(`https://beta.pizzaluce.com/api/${model}/full`)
				.then(res => res.json())
				.then(data => {
				 	if (model == 'item'){
						return data.map(obj => (
							{	id: obj._id, label: obj.name.label, 
								value: obj.name.value, 
								title: obj.name.label, 
								description: obj.description,
								locations: obj.locations,
								image: obj.image.url,
								tags: obj.tags
							 }
						))
					} else if (model == 'ingredient'){
						return data.map(ingredient => (
							{
								id: ingredient._id, label: ingredient.name.label,
								value: ingredient.name.value,
								title: ingredient.name.label,
								type: ingredient.type,
								description: ingredient.description,
								tags: ingredient.tags,
								notes: ingredient.notes
							}
						))
					} else if (model == 'category'){
						return data.map(category => (
							{
								id: category._id, label: category.name.label,
								value: category.name.value,
								title: category.name.label,
								description: category.description,
								locations: category.locations,
								author: category.author,
								corporate: category.corporate,
								created: category.created
							}
						))
					} else if (model == 'menu'){
						return data.map(menu => (
							{ 
								id: menu._id, label: menu.name.label,
								value: menu.name.value,
								title: menu.name.label,
								description: menu.description,
								locations: menu.locations,
								sections: menu.sections, 
								tags: menu.tags
							}
						))
					} else if (model == 'ordertype'){
						return data.map(ordertype => (
							{
								id: ordertype._id, label: ordertype.name.label,
								value: ordertype.name.value,
								title: ordertype.name.label,
								decscription: ordertype.description,
								locations: ordertype.locations,
								author: ordertype.author,
								corporate: ordertype.corporate
							}
						))
					} else if (model == 'tag'){
						return data.map(tag => (
							{
								id: tag._id, label: tag.name.label,
								value: tag.name.value,
								title: tag.name.label,
								description: tag.description,
								notes: tag.notes,
								author: tag.author,
								corporate: tag.corporate,
								updated: tag.updated
							}
						))
					} else if(model == 'location'){
						return data.map(location => (
							{
								id: location._id, label: location.name.label,
								value: location.name.value,
								title: location.name.label,
								address: location.address 
							}
						))
					} else if (model == 'ingredienttype'){
						return data.map(obj => (
							{
								id: obj._id, label: obj.name.label,
								value: obj.name.value,
								title: obj.name.label, 
								description: obj.description
							}
						))
					} else if(model == 'ordertype'){
						return data.map(obj => (
							{
								id: obj._id, label: obj.name.label,
								value: obj.name.value,
								title: obj.name.label,
								dewcription: obj.description
							}
						))
					}
				}
					
				)
				.catch(err => err)
		  }

		  componentDidMount(){
				 const fD = this.fetchData
				 return Promise.all([
						  fD('location'), fD('menu'), fD('item'), fD('ordertype'), 
						  fD('ingredient'), fD('ingredienttype'), fD('category'), 
						  fD('tag')
					 ])
				 .then(([locs, menus, items, oTypes, ingrdt, ingrdtTypes, cats, tags]) => {
						console.log(menus)
						this.setState({
							locations: locs,
							menus: menus,
							items: items,
							orderTypes: oTypes,
							ingredients: ingrdt,
							ingredientTypes: ingrdtTypes,
							categories: cats,
							tags: tags
						})
				 }).catch(err => err)
		  }
		  
		  toggleGallery = (event, state, index) => {
				event.preventDefault()
				this.setState({
					 [state]: index
				})
		  }
		  
		  render(){
				
					 let visibleGallery = this.state.visibleGallery
					 
					 return (
								<div style={styles.wrapper}>
									<MenuHeader 
										menus={this.state.menus}
										locations={this.state.locations}
										orderTypes={this.state.orderTypes}
										categories={this.state.categories}
										ingredients={this.state.ingredients}
										ingredientTypes={this.state.ingredientTypes}
										tags={this.state.tags}
										items={this.state.items}
									/>
										  <Container>
												<Row>
													<Col sm="4">{this.state.tabs}</Col>
													<Col sm="8">
														<div className="nav-wrapper">
																<Nav
																	className="nav-fill flex-column flex-md-row"
																	id="tabs-icons-text"
																	pills
																	role="tablist"
																>
																	<NavItem>
																		<NavLink
																				aria-selected={this.state.tabs === 'order-type'}
																				className={classnames("mb-sm-3 mb-md-0", {
																					active: this.state.tabs === 'order-type'
																				})}
																				onClick={e => this.toggleGallery(e, "tabs", 'order-type')}
																				href="#pablo"
																				role="tab"
																		>
																				<i className="ni ni-cloud-upload-96 mr-2" />
																				Order-Types
																		</NavLink>
																	</NavItem>
																	<NavItem>
																		<NavLink
																				aria-selected={this.state.tabs === 'category'}
																				className={classnames("mb-sm-3 mb-md-0", {
																					active: this.state.tabs === 'category'
																				})}
																				onClick={e => this.toggleGallery(e, "tabs", 'category')}
																				href="#pablo"
																				role="tab"
																		>
																				<i className="ni ni-bell-55 mr-2" />
																				categories
																		</NavLink>
																	</NavItem>
																	<NavItem>
																		<NavLink
																				aria-selected={this.state.tabs === 'ingredient'}
																				className={classnames("mb-sm-3 mb-md-0", {
																					active: this.state.tabs === 'ingredient'
																				})}
																				onClick={e => this.toggleGallery(e, "tabs", 'ingredient')}
																				href="#pablo"
																				role="tab"
																		>
																				Ingredients
																		</NavLink>
																	</NavItem>
																	<NavItem>
																		<NavLink
																				aria-selected={this.state.tabs === 'item'}
																				className={classnames("mb-sm-3 mb-md-0", {
																					active: this.state.tabs === 'item'
																				})}
																				onClick={e => this.toggleGallery(e, "tabs", 'item')}
																				href="#pablo"
																				role="tab"
																		>
																				Items
																		</NavLink>
																	</NavItem>
																	<NavItem>
																		<NavLink
																				aria-selected={this.state.tabs === 'menu'}
																				className={classnames("mb-sm-3 mb-md-0", {
																					active: this.state.tabs === 'menu'
																				})}
																				onClick={e => this.toggleGallery(e, "tabs", 'menu')}
																				href="#pablo"
																				role="tab"
																		>
																				Menus
																		</NavLink>
																	</NavItem>
																	<NavItem>
																		<NavLink
																				aria-selected={this.state.tabs === 'tag'}
																				className={classnames("mb-sm-3 mb-md-0", {
																					active: this.state.tabs === 'tag'
																				})}
																				onClick={e => this.toggleGallery(e, "tabs", 'tag')}
																				href="#pablo"
																				role="tab"
																		>
																				Tags
																		</NavLink>
																	</NavItem>
																</Nav>
														</div>
													</Col>
												</Row>
										  </Container>
									 <TabContent activeTab={"tabs" + this.state.tabs}>
										<TabPane tabId="tabscategory">
											<CardGalleryHorizontal 
														title='categories'
														model={'category'}
														data={this.state.categories}
											/>
										</TabPane>
										<TabPane tabId="tabsorder-type">
											<CardGalleryHorizontal 
												title='order-types'
												model={'ordertype'}
												data={this.state.orderTypes}
											/>
										</TabPane>
										<TabPane tabId="tabsitem">
											<CardGalleryHorizontal 
												title={'items'}
												model={'item'}
												data={this.state.items}
											/>
										</TabPane>
										<TabPane tabId="tabsingredient">
											<CardGalleryHorizontal 
												title='ingredient'
												model={'ingredient'}
												data={this.state.ingredients}
												tags={this.state.tags}
											/>
										</TabPane>
										<TabPane tabId="tabsmenu">
											<CardGalleryHorizontal 
												title='Menus'
												model={'menu'}
												data={this.state.menus}
												items={this.state.items}
											/>
										</TabPane>
										<TabPane tabId="tabstag">
											<CardGalleryHorizontal 
												title='Tags'
												model={'tag'}
												data={this.state.tags}
											/>
										</TabPane>
									 </TabContent>
								</div>   
					 )
		  }
}

export default Menu