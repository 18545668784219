import React from 'react'
import { FormGroup, Input, Container, Button } from "reactstrap"
import { useInput } from '../../hooks/useInput';
import { post } from 'tools/Restful'

const NewIngredientType = () => {
    const { value:label, bind:bindlabel, reset:resetlabel } = useInput('')
    const { value:description, bind:binddescription, reset:resetdescription } = useInput('')
    const {value:notes, bind:bindnotes, reset:resetnotes } = useInput('')

    const submitData = (e) => {
        e.preventDefault()
        prepareIngredientType(e)
        resetlabel()
        resetnotes()
        resetdescription()
    }
    const prepareIngredientType = () => {
        let NewIngredientType = {
            name: {
                label: label,
                value: label.replace(/ /g, '_').toLowerCase()
            },
            description: description,
            notes: notes
        }
        console.log(NewIngredientType)
        let submitIngredientType = () => new Promise((r) => r(post(`ingredienttype`, NewIngredientType)))
        submitIngredientType().then(data => console.log(data)).catch(err => console.log(err))
    }
    return(
        <Container>
            <FormGroup>
                <label>Name</label>
                <Input 
                    type="test"
                    {...bindlabel}
                />
            </FormGroup>
            <FormGroup>
                <label>Internal Name</label>
                <Input
                    className="internal"
                    name="internal"
                    type="text"
                    value={label.replace(/ /g, '_').toLowerCase()}
                    readOnly
                />
            </FormGroup>
            <FormGroup>
                <label>Description</label>
                <Input
                    type="textarea"
                    {...binddescription}
                />
            </FormGroup>
            <FormGroup>
                <label>Notes</label>
                <Input
                    type="textarea"
                    {...bindnotes}
                />
            </FormGroup>
            <Button
                color="primary"
                onClick={event => submitData(event)}
            >
                submit
            </Button>
        </Container>
    )
}
export default NewIngredientType