import React from "react"

import {
    FormGroup,
    Form, Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Button,
    Row, Col
} from "reactstrap"
import Select, {components} from 'react-select'

class NewCategory extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            name: '',
            label: '',
            internal: '',
            description: '',
            chosenLocations: [],
            chosenOrderTypes: []
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleLocations = this.handleLocations.bind(this)
        this.handleOrderTypes = this.handleOrderTypes.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleInputChange(event){
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        const internal_name = value.replace(/ /g, '_').toLowerCase()

       this.setState({
            [name]: value
        })

        if(name === 'name'){
             
            this.setState({
                label: value,
                internal: internal_name
            })
        }
    }
    handleLocations(event){
        console.log(event)
        let selectedIds = event.map(data => {
            return {label: data.label, value: data.value, id: data.id}
        })
        this.setState({
            chosenLocations: selectedIds.map(location => location.id)
        })
    }
    handleOrderTypes(event){
        console.log(event)
        let selectedOrderTypes = event.map(data => {
            return {label: data.label, value: data.value, id: data.id}
        })
        this.setState({
            chosenOrderTypes: selectedOrderTypes.map(orderType => orderType.id)
        })
    }
    handleSubmit(event){
        const NewCategory = {
            name: this.state.name,
            label: this.state.label,
            internal: this.state.internal,
            description: this.state.description,
            locations: this.state.chosenLocations,
            orderTypes: this.state.chosenOrderTypes
        }
        fetch('http://localhost:3000/api/category',{
            method: 'POST',
            body: JSON.stringify(NewCategory),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            let data = res.json()
            return data
        }).then((data) => {
            console.log(data)
        }).catch((err) => err)
        event.preventDefault()
    }
    render(){
        console.log(this.state)
        return(
            <Form className="package">
                <FormGroup>
                    <label>Name</label>
                    <Input
                        name="name"
                        id="name"
                        type="text"
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Internal Name</label>
                    <Input
                        name="internal"
                        id="internal"
                        type="text"
                        onChange={this.handleInputChange}
                        value={this.state.internal}
                        readOnly
                    />
                </FormGroup>
                <FormGroup>
                    <label>Description</label>
                    <Input
                        name="description"
                        type="textarea"
                        rows="3"
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Locations</label>
                    <Select
                        name='locations'
                        closeMenuOnSelect={false}
                        isMulti
                        options={this.props.locations}
                        onChange={this.handleLocations}
                    />
                </FormGroup>
                <FormGroup>
                     <label>Order Types</label>
                     <Select 
                        name='ordertypes'
                        closeMenuOnSelect={false}
                        isMulti
                        options={this.props.orderTypes}
                        onChange={this.handleOrderTypes}
                     />
                </FormGroup>
                <Button
                    color="primary"
                    onClick={this.handleSubmit}
                >
                    submit
                </Button>
            </Form>
        )
    }
}
export default NewCategory