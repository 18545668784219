import React from "react";
import classnames from "classnames";

import {
    
    Container, NavItem, NavLink, Nav, TabContent, TabPane,
    Row,
    Col,
   
  } from "reactstrap";

import LocationHeader from "components/Headers/LocationHeader";
import CardGalleryHorizontal from 'components/Galleries/CardGalleryHorizontal'
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import CardsHeader from "components/Headers/CardsHeader.jsx";
import UntappdMenuEdit from "views/pages/location/UntappdMenuEdit";
import StatusBar from "views/pages/location/UntappdMenuStatusBar";
import { widgetEvents } from "variables/general.jsx";
import styles from "views/pages/location/styles.css"


let calendar;

class LocationAll extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loc: {
                name: props.location.name,
                id: props.location.id
            },
            locations: [],
            hours: [],
            promos: [],
            slices: [],
            untappd_menus: [],
            positions: [],
            visibleGallery: ''
        }
        this.fetchData = this.fetchData.bind(this)
    }
    fetchData(model){
        let url = `http://localhost:5000/api/${model}/full`
        if (model == 'hours'){
            url = `http://localhost:5000/api/${model}`
        } 
        return fetch(url)
        .then(res => res.json())
        .then(data => {
            if (model == 'location'){
                return data.map(obj => (
                    {
                        id: obj._id, label: obj.name.label,
                        value: obj.name.value,
                        title: obj.name.label,
                        coordinates: {
                            lat: obj.meta_data.coordinates.lat, 
                            lng: obj.meta_data.coordinates.lng, 
                            radius: obj.meta_data.coordinates.radius
                        },
                        keys: {
                            breezy: obj.meta_data.breezy_id,
                            untappd: obj.meta_data.untappd_id,
                            foodtec: obj.meta_data.foodtec_id,
                            featured_beer: obj.meta_data.featured_beer
                        },
                        image: obj.image.url,
                        address: {
                            street: obj.address,
                            city: obj.city, 
                            zip: obj.zip,
                            phone: obj.phone,
                        },
                        online_ordering: obj.online_ordering_url
                    }
                ))
            } 
        }).catch(err => err)
        
    }
    componentDidMount(){
       /* fetch(`http://localhost:5000/api/location/${this.state.loc.id}/full`)
        .then(res => res.json())
        .then((data) => {
            this.setState({
                untappd_menus: data.untappd_menus,
                positions: data.breezy_positions,
                delivery_area: data.delivery_area,
            })
        }) */
        const fD = this.fetchData
        return Promise.all([
            fD('location'), fD('tag')
        ])
        .then(([locs, tags]) => {
            this.setState({
                locations: locs,
                tags: tags
            })
        }).catch(err => err)
    }
    toggleGallery = (event, state, index) => {
        event.preventDefault()
        this.setState({
            [state]: index
        })
    }
    render(){
        console.log(this.state)
        return (
            <div style={styles.wrapper}>
               <LocationHeader 
                    locations={this.state.locations}
               />
               <Container>
                   <Row>
                       <Col sm="4">{this.state.tabs}</Col>
                       <Col sm="8">
                           <div className="nav-wrapper">
                                <Nav
                                    className="nav-fill flex-column flex-md-row"
                                    id="tabs-icons-text"
                                    pills
                                    role="tablist"
                                >
                                    <NavItem>
                                        <NavLink
                                            aria-selected={this.state.tabs === 'locations'}
                                            className={classnames("mb-sm-3 mb-md-0", {
                                                active: this.state.tabs === 'locations'
                                            })}
                                            onClick={e => this.toggleGallery(e, "tabs", 'locations')}
                                            href="#pablo"
                                            role="tab"
                                        >
                                            <i className="ni ni-cloud-upload-96 mr-2" />
                                            Locations
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            aria-selected={this.state.tabs === 'hours'}
                                            className={classnames("mb-sm-3 mb-md-0", {
                                                active: this.state.tabs === 'hours'
                                            })}
                                            onClick={e => this.toggleGallery(e, "tabs", 'hours')}
                                            href="#pablo"
                                            role="tab"
                                        >
                                            <i className="ni ni-cloud-upload-96 mr-2" />
                                            Hours
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            aria-selected={this.state.tabs === 'promos'}
                                            className={classnames("mb-sm-3 mb-md-0", {
                                                active: this.state.tabs === 'promos'
                                            })}
                                            onClick={e => this.toggleGallery(e, "tabs", 'promos')}
                                            href="#pablo"
                                            role="tab"
                                        >
                                            <i className="ni ni-cloud-upload-96 mr-2" />
                                            Promos
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            aria-selected={this.state.tabs === 'slices'}
                                            className={classnames("mb-sm-3 mb-md-0", {
                                                active: this.state.tabs === 'slices'
                                            })}
                                            onClick={e => this.toggleGallery(e, "tabs", 'slices')}
                                            href="#pablo"
                                            role="tab"
                                        >
                                            <i className="ni ni-cloud-upload-96 mr-2" />
                                            Slices
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            aria-selected={this.state.tabs === 'untappd-menus'}
                                            className={classnames("mb-sm-3 mb-md-0", {
                                                active: this.state.tabs === 'untappd-menus'
                                            })}
                                            onClick={e => this.toggleGallery(e, "tabs", 'untappd-menus')}
                                            href="#pablo"
                                            role="tab"
                                        >
                                            <i className="ni ni-cloud-upload-96 mr-2" />
                                            Untappd-Menus
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            aria-selected={this.state.tabs === 'Positions'}
                                            className={classnames("mb-sm-3 mb-md-0", {
                                                active: this.state.tabs === 'positions'
                                            })}
                                            onClick={e => this.toggleGallery(e, "tabs", 'positions')}
                                            href="#pablo"
                                            role="tab"
                                        >
                                            <i className="ni ni-cloud-upload-96 mr-2" />
                                            Positions
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                           </div>
                       </Col>
                   </Row>
               </Container>
                <TabContent activeTab={"tabs" + this.state.tabs}>
                    <TabPane tabId="tabslocations">
                        <CardGalleryHorizontal 
                            title={'locations'}
                            model={'locations'}
                            data={this.state.locations}
                        />
                    </TabPane>                          
                </TabContent>
            </div>
        )
    }
}
export default LocationAll