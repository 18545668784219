import React from "react";
import NewIngredient from 'components/Forms/NewIngredient'
import NewItem from 'components/Forms/NewItem'
import NewCategory from 'components/Forms/NewCategory'
import NewOrderType from 'components/Forms/NewOrderType'
import NewTag from 'components/Forms/NewTag'
import NewIngredientType from 'components/Forms/NewIngredientType'
import NewMenu from 'components/Forms/NewMenu'
import CardGalleryHorizontal from 'components/Galleries/CardGalleryHorizontal'
import ModalX from 'components/Modals/formModal'
import ReactBSAlert from 'react-bootstrap-sweetalert'
// reactstrap components
import { 
  Button, 
  Card, 
  CardHeader, 
  CardImg,
  CardBody, 
  Modal, 
  Container, 
  Row, 
  Col } from "reactstrap"

  // tools
  import {get} from 'tools/Restful'
  import NotificationContainer from 'components/Notifications/NotificationContainer'
  import NotificationContainer2 from 'components/Notifications/NotificationContainer2'
  import Notification from 'components/Notifications/Notification2'
  import styles from 'components/Headers/HeaderStyles.css'
  import FormStyles from 'components/Forms/FormStyles.css'

class MenuHeader extends React.Component {
  constructor(props){
    console.log(props)
    super(props)
    this.state = {
      showModal: false,
      defaultModal: false,
      locations: [],
      orderTypes: [],
      ingredients: [],
      categories: [],
      tags: [],
      alert: false
    }
    this.handleShow = this.handleShow.bind(this)
    this.handleHide = this.handleHide.bind(this)
    this.showAlert = this.showAlert.bind(this)
    console.log(props)
  }
  showAlert(){
    this.setState({
      alert: true
    });
    console.log(this.state)
  }
  hideAlert(){
    this.setState({
      alert: false
    });
  };
  toggleAlert = state => {
    this.setState({
      [state]: !this.state[state]
    })
  }
  handleShow(){
    this.setState({showModal: true})
    console.log(this.state)
  }
  handleHide(){
    this.setState({showModal: false})
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    })
  }
  
  render() {
    console.log(this.state)
    console.log(this.props)
  
    return (
      <>
        <div
          className="header pb-6 d-flex align-items-center"
          style={{
            minHeight: "500px",
            backgroundImage:
              'url("' + require("assets/img/theme/profile-cover.jpg") + '")',
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
        >
         
          <span className="mask bg-gradient-info opacity-8" />
          
           {
             this.state.alert === true ?
              <NotificationContainer
                
                title='Test Title 2'
                text="Test Text 2"
              />
              :
              <span></span>
           }
          
          
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="7" md="10">
                <h1 className="display-2 text-white">Menus</h1>
                <p className="text-white mt-0 mb-5">
                  This is your profile page. You can see the progress you've
                  made with your work and manage your projects or assigned tasks
                </p> 
                <Button
                  onClick={() => this.toggleModal('alert')}
                >
                  test alert
                </Button>
                <Button
                  className="modal-trigger"
                  color="default"
                  onClick={() => this.toggleModal('formModal')}
                >
                  Create Item Ingredient
                </Button>
                <Button
                  className="modal-trigger"
                  color="default"
                  onClick={() => this.toggleModal('itemModal')}
                >
                  Create Menu Item
                </Button>
                <Button
                  className="modal-trigger"
                  color="primary"
                  onClick={() => this.toggleModal('categoryModal')}
                >
                  new Category
                </Button>
                <Button
                  className="modal-trigger"
                  color="warning"
                  onClick={() => this.toggleModal('orderTypeModal')}
                >
                  Create Ordertype
                </Button>
                <Button
                  className='modal-trigger'
                  color='alert'
                  onClick={() => this.toggleModal('tagModal')}
                >
                  new tag
                </Button>
                <Button
                  className='modal-trigger'
                  color='alert'
                  onClick={() => this.toggleModal('ingredientTypeModal')}
                >
                  new ingredient-type
                </Button>
                
                <Button
                  className="modal-trigger"
                  color='alert'
                  onClick={() => this.toggleModal('menuModal')}
                >
                  new menu
                </Button>
                <Modal
                  className="modal-dialog-centered spacer"
                  isOpen={this.state.menuModal}
                  toggle={() => this.toggleModal("menuModal")}
                >
                  <Card className="shadow border-0 card-profile">
                    <CardImg
                      alt="..."
                      src={'https://picsum.photos/200/100'}
                      top
                    />
                    <Row className="justify-content-center">
                      <Col className="order-lg-2" lg="3">
                        <div className="card-profile-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={"https://picsum.photos/100/100"}
                            />
                          </a>
                        </div>
                      </Col>
                     
                    </Row>
                        <CardHeader>
                          <div className="d-flex justify-content-between">
                            <h4>Create Menu</h4>
                          </div>
                        </CardHeader>  
                        <CardBody className="pt-0">
                          <NewMenu 
                            locations={this.props.locations}
                          />
                        </CardBody>
                    </Card>
                </Modal>
                <Modal
                  className="modal-dialog-centered spacer"
                  isOpen={this.state.formModal}
                  toggle={() => this.toggleModal("formModal")}
                >
                  
                    <Card className="shadow border-0 card-profile">
                    <CardImg
                      alt="..."
                      src={'https://picsum.photos/200/100'}
                      top
                    />
                    <Row className="justify-content-center">
                      <Col className="order-lg-2" lg="3">
                        <div className="card-profile-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={"https://picsum.photos/100/100"}
                            />
                          </a>
                        </div>
                      </Col>
                     
                    </Row>
                        <CardHeader>
                          <div className="d-flex justify-content-between">
                            <h4>Create Ingredient</h4>
                          </div>
                        </CardHeader>  
                        <CardBody className="pt-0">
                          <NewIngredient 
                            tags={this.props.tags}
                            ingredientTypes={this.props.ingredientTypes}
                          />
                        </CardBody>
                    </Card>
                </Modal>
                <Modal
                  className="modal-dialog-centered"
                  isOpen={this.state.itemModal}
                  toggle={() => this.toggleModal("itemModal")}
                >
                  <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                      <Container className="d-flex align-items-center">
                        <CardBody className="">
                          <NewItem
                            locations={this.props.locations}
                            ingredients={this.props.ingredients}
                            orderTypes={this.props.orderTypes}
                            categories={this.props.categories}
                            tags={this.props.tags}
                            

                          />
                        </CardBody>
                      </Container>
                    </Card>
                  </div>
                </Modal>
                <Modal
                  className="modal-dialog-centered"
                  isOpen={this.state.categoryModal}
                  toggle={() => this.toggleModal("categoryModal")}
                >
                  <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                      <Container className="d-flex align-items-center">
                        <CardBody className="">
                          <NewCategory 
                            locations={this.props.locations}
                            orderTypes={this.props.orderTypes}
                          />
                        </CardBody>
                      </Container>
                    </Card>
                  </div>
                </Modal>
                <Modal
                  className="modal-dialog-centered"
                  isOpen={this.state.orderTypeModal}
                  toggle={() => this.toggleModal("orderTypeModal")}
                >
                  <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                      <Container className="d-flex align-items-center">
                        <CardBody className="">
                          <NewOrderType
                            locations={this.props.locations}
                          />
                        </CardBody>
                      </Container>
                    </Card>
                  </div>
                </Modal>
                <Modal
                  className="modal-dialog-centered"
                  isOpen={this.state.tagModal}
                  toggle={() => this.toggleModal("tagModal")}
                >
                  <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                      <Container className="d-flex align-items-center">
                        <CardBody className="">
                          <NewTag /> 
                        </CardBody>
                      </Container>
                    </Card>
                  </div>
                </Modal>
                <Modal
                  className="modal-dialog-centered spacer"
                  isOpen={this.state.ingredientTypeModal}
                  toggle={() => this.toggleModal("ingredientTypeModal")}
                >
                    <Card className="shadow border-0 card-profile">
                    <CardImg
                      alt="..."
                      src={'https://picsum.photos/200/100'}
                      top
                    />
                    <Row className="justify-content-center">
                      <Col className="order-lg-2" lg="3">
                        <div className="card-profile-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={"https://picsum.photos/100/100"}
                            />
                          </a>
                        </div>
                      </Col>
                     
                    </Row>
                        <CardHeader>
                          <div className="d-flex justify-content-between">
                            <h4>Create Ingredient-type</h4>
                          </div>
                        </CardHeader>  
                        <CardBody className="pt-0">
                          <NewIngredientType />
                        </CardBody>
                    </Card>
                </Modal>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default MenuHeader;
