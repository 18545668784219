import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { createPortal } from "react-dom"
import styled from "styled-components"
import PropTypes from "prop-types"

class ModalX extends React.Component {
	constructor(props){
		super(props)
		this.el = document.createElement('div')
	}
	componentDidMount(){
		document.getElementById('modal').appendChild(this.el)
	}
	componentWillUnmount(){
		document.getElementById('modal').removeChild(this.el)
	}

	render(){
		return ReactDOM.createPortal(
			this.props.children,
			this.el
		)
	}
}
export default ModalX