import React, { useState } from 'react'
import { 
    Alert, Badge, Button, Card, CardHeader, CardBody, CardFooter,
    CardImg, CardImgOverlay, CardTitle, CardText, Container, 
    FormGroup, Input, ListGroupItem, ListGroup, Row, Col
} from "reactstrap";
import toaster from 'toasted-notes'
import 'toasted-notes/src/styles.css'

import styles from 'components/Galleries/Cards/CardStyles.css'

const portalRoot = document.getElementById('root')

const LocationCard = (props) => {
    const [state, setState] = useState({editMode: false, alert: false})
    const card = props.data
    const model = props.model
    const title = props.title

    const setEditMode = (e) => {
        setState({editMode:true})
    }
    const resetEditMode = (e) => {
        setState({
            editMode: false
        })
    }

    return (
        <Card className="card card-content">
            {   state.editMode === false
                ?   <div>
                        <CardImg
                            alt="..."
                            src={card.image.url}
                            top
                        />
                        <CardBody
                            key={card.id}
                        >
                            <CardTitle>{card.label}</CardTitle>
                            <Container className="item-card-location-container">
                                <strong>hours</strong>
                                <Button
                                    key={card.id}
                                    size='sm'
                                    className='edit-button'
                                    color='primary'
                                    href={`/admin/downtown`}
                                >
                                    view
                                </Button>
                            </Container>
                        </CardBody>
                    </div>

                :   <div>
                        <CardBody>

                        </CardBody>
                    </div>
                    
            }
        </Card>
    )
}
export default LocationCard
