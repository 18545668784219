import React from "react"

import { FormGroup, Form, Input, Button } from "reactstrap"
import ItemPriceInput from 'components/Forms/ItemPriceInput'
import ItemDescriptionInput from 'components/Forms/ItemDescriptionInput'
import Select, {components} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { get, post } from '../../tools/Restful'

class NewMenu extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            name: '',
            internal: '',
            description: '',
            notes: '',
            locations: [],
            sections: [],
            tags: [],
            chosenTags: [],
            chosenLocations: [],
            newTags: [] //this is for user indicating user created tags which need to be submnitted too

        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.prepareMenu = this.prepareMenu.bind(this)
        this.handleMenuLocations = this.handleMenuLocations.bind(this)

    }
    handleInputChange(event){
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        const internal_name = value.replace(/ /g, '_').toLowerCase()

        this.setState({[name]: value})

        if(name === 'name'){
            this.setState({name: value, internal: internal_name})
        }
    }
    handleMenuLocations = (event) => {
        let selected = event
        this.setState({chosenLocations: selected})
    }
    prepareMenu = (event) => {
        console.log(this.state)
        event.preventDefault()
        let newMenu = {
            name: this.state.name,
            label: this.state.internal,
            description: this.state.description,
            notes: this.state.notes,
            locations: this.state.chosenLocations.map(location => location.id)
        }
        let submitMenu = () => new Promise((r) => r(post('menu', newMenu)))
        submitMenu().then(data => console.log(data)).catch(err => console.log(err))
    }
    render(){
        return (
            <Form className="package">
                <FormGroup>
                    <label htmlFor="name">Name</label>
                    <Input
                        className="stuff"
                        name="name"
                       
                        type="text"
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="name">Internal Name</label>
                    <Input
                        className="internal"
                        name="internal"
                        
                        type="text"
                        value={this.state.internal}
                        readOnly
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="description">Description</label>
                    <Input
                        name="description"
                        type="textarea"
                        rows="3"
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Notes</label>
                    <Input
                        id="notes"
                        name="notes"
                        type="textarea"
                        rows="3"
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <label>Locations</label>
                    <Select
                        
                        closeMenuOnSelect={false}
                        isMulti
                        options={this.props.locations}
                        onChange={this.handleMenuLocations}
                        value={this.state.chosenLocations}
                    />
                </FormGroup>
                <Button
                    onClick={this.prepareMenu}
                >
                    Submit!
                </Button>
            </Form>
        )
    }
}
export default NewMenu