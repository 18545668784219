//Refactor this piece of shit too

import React from "react"

import { FormGroup, Form, Input, Button } from "reactstrap"
import ItemPriceInput from 'components/Forms/ItemPriceInput'
import PriceInput from 'components/Forms/PriceInput'
import ItemDescriptionInput from 'components/Forms/ItemDescriptionInput'
import Select, {components} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { get, post } from '../../tools/Restful'


class NewItem extends React.Component {
    constructor(){
        super()
        this.state = {
            item: {
                name: '',
                tags: [],
                ingredients: [],
                locations: [],
                prices: [],
                description: [],
                order_types: [],
                notes: [],
                notes: '',
            },
            ingredients: [],
            chosenIngredients:[],
            locations: [],
            chosenLocations: [],
            orderTypes: [],
            chosenOrdertypes: [],
            tags: [],
            chosenTags: [],
            notes: '',
            itemImage: '',
            category: [],
            chosenCategory: [],
            descriptions: [],
            prices: [],
            name: '',
            label: '',
            newTags: []
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleItemIngredients = this.handleItemIngredients.bind(this)
        this.handleItemLocations = this.handleItemLocations.bind(this)
        this.submitItem = this.submitItem.bind(this)
        this.handleItemOrderTypes = this.handleItemOrderTypes.bind(this)
        this.handleTags = this.handleTags.bind(this)
        this.handleFile = this.handleFile.bind(this)
        this.postItem = this.postItem.bind(this)
        this.handleCategory = this.handleCategory.bind(this)
        this.callbackFunction = this.callbackFunction.bind(this)
        this.priceCallback = this.priceCallback.bind(this)
        this.addNewTagToDB = this.addNewTagToDB.bind(this)
        
    }
    callbackFunction = (childData) => {
        this.setState({
            descriptions: [...childData]
        })
        console.log(childData)
    }
    priceCallback = (childData) => {
        this.setState({
            prices: [...childData]
        })
    }
    addNewTagToDB(tagArray){
        console.log(tagArray)
    }
    handleInputChange(event){
        console.log(this.state)
        console.log(this.props)
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        const internal_name = value.replace(/ /g, '_').toLowerCase()

        this.setState({
            [name]: value
        })
        
        if(name === 'name'){
             
            this.setState({
                label: value,
                internal: internal_name
            })
        }
    }
    handleCategory(event){
        let selected = event
        console.log(selected)
        this.setState({
            chosenCategory: selected
        })
    }
    handleItemIngredients(event){
        let selected = event
        console.log(selected)
        this.setState({
            chosenIngredients: selected
        })
    }

    handleItemLocations(event){
        let selected = event
        this.setState({
            chosenLocations: selected
        })
    }
    handleItemOrderTypes(event){
        let selected = event
        console.log(event)
        this.setState({
            chosenOrdertypes: selected
        })
    }
    handleTags(event){
        console.log(event)
        let selected = event
        let newTags
        this.setState({
            chosenTags: selected
        })
        
        if (event) {
           newTags = event.filter(tag => {
                return tag.__isNew__ === true
            })
            if (newTags.length > 0) {
                this.setState({
                    newTags: newTags
                })
            }
        }
        
    }
    handleFile(FileList){
        this.setState({
            itemImage: FileList
        })
    }
    postItem(event, newItem){

        console.log(newItem)
        const item_image_input = document.getElementById('item-image-input')
        const data = new FormData
            data.append("name", newItem.label)
            data.append("label", newItem.internal)
            data.append("tags",  newItem.tags)
            data.append("description", JSON.stringify(newItem.description))
            data.append("ingredients", newItem.ingredients)
            data.append("order_types", newItem.order_types)
            data.append("locations", newItem.locations)
            data.append("category", newItem.category)
            data.append("prices", newItem.prices)
            data.append("notes", newItem.notes)
            data.append("imagename", item_image_input.files[0])

        fetch('https://localhost:5000/api/item', {
            method: 'POST',
            body: data,
        
        }).then((res) => {
            console.log(res.status)
            
            let data = res.json()
            console.log(data)
            return data
        }).then((data) => {
            console.log(data)
            return data
        })
        .catch((err) => err)
        event.preventDefault()
    }

    submitItem(event, props){

        
        let addTagsToDB = this.state.newTags.map(tag => ({label: tag.label, value: (tag.value).toLowerCase()}))
        let categoryArray = this.state.chosenOrdertypes
        let categoryId = this.state.chosenOrdertypes[0].id
        let arrayF = Array.from(this.state.chosenOrdertypes)

        console.log(arrayF)
        
        console.log(categoryArray)
        console.log(categoryId)
        addTagsToDB.map(tag => {
            post('tag', tag)
            console.log(tag)
        })

        let newItem = {
           
                label: this.state.name,
                internal: this.state.internal,
            
            category: this.state.chosenCategory.id,
            locations: this.state.chosenLocations.map(location => location.id),
            ingredients: this.state.chosenIngredients.map(ingredient => ingredient.id),
            order_types: this.state.chosenOrdertypes.map(orderType => orderType.id),
            description: this.state.descriptions,
            prices: this.state.prices,
            tags: this.state.chosenTags.map(tag => tag.id),
            notes: this.state.notes,
            imagename: this.state.itemImage
        }
        console.log(newItem)
        let newTags = this.state.newTags
        console.log(newTags)
        
        this.postItem(event, newItem)
    }
    testChange(event){
        console.log(event)
    }
    render(){
        let tags, tagArray, category, orderTypes, ingredients

        if (this.props.orderTypes){
            orderTypes = this.props.orderTypes.map(orderType => orderType.id)
        } else if (this.state.chosenTags){
            tags = this.state.chosenTags.map(tag => tag)
            tagArray = tags.filter(tag => tag.value === 'gluten-free-on-request' || tag.value === 'vegan-on-request')
        } else if (this.props.tags){
            tags = this.props.tags.map(tag => tag.id)
        } else if (this.props.ingredients){
            ingredients = this.props.ingredients.map(ingredient => ingredient.id)
        } else if (this.props.categories){
            category = this.props.categories.map(category => category.id)
        } else {

        }
        
        return(
                <Form className="package">
               
                    <FormGroup>
                        <label>Name</label>
                        <Input
                            className="stuff"
                            name="name"
                            id="name"
                            type="text"
                            onChange={this.handleInputChange}
                        />
                    </FormGroup>
                    
                    <FormGroup>
                        <label>Internal Name</label>
                        <Input
                            className="internal"
                            name="internal"
                            id="internal"
                            type="text"
                            onChange={this.handleInputChange}
                            value={this.state.internal}
                            readOnly
                        />
                    </FormGroup>

                    <FormGroup>
                        <label>Locations</label>
                        <Select
                            id={'locations'}
                            closeMenuOnSelect={false}
                            isMulti
                            options={this.props.locations}
                            onChange={this.handleItemLocations}
                            value={this.state.chosenLocations}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label>Order Types</label>
                        <Select
                            name="order_types"
                            id={'order_types'}
                            closeMenuOnSelect={false}
                            isMulti
                            options={this.props.orderTypes}
                            onChange={this.handleItemOrderTypes}
                            value={this.state.chosenOrdertypes}
                        />
                        
                    </FormGroup>

                    <FormGroup>
                        <label>Tags</label>
                        <CreatableSelect
                            isMulti
                            options={this.props.tags}
                            onChange={this.handleTags}
                            value={this.state.chosenTags}
                        />
                    </FormGroup>
                    
                    <FormGroup>
                        <label>Ingredients</label>
                        <Select
                            name='chosenIngredients'
                            id={'select'}
                            closeMenuOnSelect={false}
                            isMulti
                            options={this.props.ingredients}
                            onChange={this.handleItemIngredients}
                            value={this.state.chosenIngredients}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label>Description</label>
                        <ItemDescriptionInput 
                            orderTypes={this.state.chosenOrdertypes}
                            parentCallback = {this.callbackFunction}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label>Category</label>
                        <Select
                            name="category"
                            options={this.props.categories}
                            onChange={this.handleCategory}
                            value={this.state.chosenCategory}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label>Prices</label>
                        <PriceInput 
                            tags={this.state.chosenTags}
                            category={this.state.chosenCategory}
                            parentCallback={this.priceCallback}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label>Notes</label>
                        <Input
                            id="notes"
                            name="notes"
                            type="textarea"
                            rows="3"
                            onChange={this.handleInputChange}
                        />
                    </FormGroup>
            
                    <FormGroup>
                        <label>Item Image</label>
                        <div className="custom-file">
                        
                        <Input
                            className='custom-file-input'
                            id="item-image-input"
                            type="file"
                            onChange={(event) => this.handleFile(event.target.files)}
                        />
                        <label className="custom-file-label" htmlFor="customFileLang">
                            Select file
                        </label>
                        </div>
                    </FormGroup>
                    <Button
                        onClick={this.submitItem}
                    >
                        Submit!
                    </Button>
                </Form>
        )
    }
}
export default NewItem








