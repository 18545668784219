import React from "react";

// reactstrap components
import { 
  Button, 
  Card, 
  CardHeader, 
  CardImg,
  CardBody, 
  Modal, 
  Container, 
  Row, 
  Col } from "reactstrap"

import NotificationContainer from 'components/Notifications/NotificationContainer'

class LocationHeader extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            showModal: false,
            defaultModal: false,
            locations: []
        }
    }
    toggleAlert = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }
    render() {
        
        return (
        <>
            <div
            className="header pb-6 d-flex align-items-center"
            style={{
                minHeight: "400px",
                backgroundImage:
                'url("' + require("assets/img/theme/profile-cover.jpg") + '")',
                backgroundSize: "cover",
                backgroundPosition: "center top"
            }}
            >
            <span className="mask bg-gradient-danger opacity-8" />

            <Container className="d-flex align-items-center" fluid>
                <Row>
                <Col lg="7" md="10">
                    <h1 className="display-2 text-white"> Location {this.props.locationName}</h1>
                    <p className="text-white mt-0 mb-5">
                    Import messages go here
                    </p>
                    
                </Col>
                </Row>
            </Container>
            </div>
        </>
        );
    }
}

export default LocationHeader;
