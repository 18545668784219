import React, { useState } from 'react'
import { 
    Alert, Badge, Button, Card, CardHeader, CardBody, CardFooter,
    CardImg, CardImgOverlay, CardTitle, CardText, Container, 
    Form, FormGroup, Input, ListGroupItem, ListGroup, Row, Col
} from "reactstrap"
import PriceInput from 'components/Forms/PriceInput'
import ItemDescriptionInput from 'components/Forms/ItemDescriptionInput'
import toaster from 'toasted-notes'
import CreatableSelect from 'react-select/creatable'
import { get, post, remove } from 'tools/Restful'
import 'toasted-notes/src/styles.css'

import styles from 'components/Galleries/Cards/CardStyles.css'

const portRoot = document.getElementById('portal')

const PromosCard = (props) => {
    const [state, setState] = useState({editMode: false, alert: false})
    const card = props.data
    const model = props.model
    const title = props.title

    const setEditMode = (e) => {
        setState({editMode:true})
    }
    const resetEditMode = (e) => {
        setState({
            editMode: false
        })
    }
    return(
        <Card className="card card-content">
            {   state.editMode === false
                ?   <div>
                        <CardBody key={card.id}>
                            <CardTitle>{card.label}</CardTitle>
                            <CardText>{card.description}</CardText>
                            <Container className="item-card-location-container">
                                <strong>locations</strong>
                                {card.locations.map(location => (
                                    <span>
                                        <Badge color="info" className="location-badge">
                                        
                                        </Badge>
                                    </span>
                                ))}
                            </Container>

                        </CardBody>
                    </div>
                :
                    <div>

                    </div>
            }
        </Card>
    )
}
export default PromosCard