import React from 'react'
import NotificationAlert from 'react-notification-alert'
import { Button, Container } from 'reactstrap'

class NotificationContainer extends React.Component {
    constructor(props){
        super(props)
        this.notify = this.notify.bind(this)
        
    }
    notify = (type, text, title) => {
        
        let options = {
            place: "tc",
            message: (<div>{
                <Container>
                    <div className="alert-text">
                        <span className="alert-title" data-notify="title">
                            {title}
                        </span>
                        <span data-notify="message">
                            {text}
                        </span>
                    </div>
                </Container>
            }
            </div>), 
            type: type,
            icon: 'ni ni-bell-55',
            autoDismiss: 2
        }
        this.refs.notificationAlert.notificationAlert(options)
    }
    componentDidMount(){
        let text = this.props.text
        let title = this.props.title
        this.notify("success", text, title)
    }
    render(){
        let title = this.props.title
        let text = this.props.text
        return(
            <>
                <div className="rna-wrapper">
                   
                    <NotificationAlert 
                        ref="notificationAlert" 
                        title={title}
                        text={text}
                    />
       
                </div>

            </>
        )   
    }
}
export default NotificationContainer